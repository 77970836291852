import NotFoundPage from "../components/pages/404";
import Language from "../translations/translator";

const NotFound = (props) => (
  <Language language="pt">
    <NotFoundPage {...props} />
  </Language>
);

export default NotFound;
