import React from "react";
import styled from "@emotion/styled";
import { Link as GatsbyLink } from "gatsby";
import { useT } from "../../translations/translator";

import SEO from "../seo";
import Container from "../container";
import Footer from "../footer";
import Header from "../header";

const Link = styled(GatsbyLink)`
  font-family: "Hightlight";
  color: var(--text-color-hightlight);
`;
const Layout = styled.div`
  display: grid;
  min-height: 100vh;
  grid-template-rows: auto 1fr auto;
`;

const NotFoundPage = ({ pageContext }) => {
  const { t } = useT();

  return (
    <Layout>
      <SEO
        lang={t("language")}
        title={t("notFoundtitle")}
        description={t("notFounddescription")}
        context={pageContext}
      />
      <Header />
      <main style={{ overflow: "hidden" }}>
        <Container>
          <h1>{t("notFoundtitle")}</h1>
          <p>
            {t("notFoundtext")} <Link to="/">{t("notFoundgoBack")}</Link>
          </p>
        </Container>
      </main>
      <Footer />
    </Layout>
  );
};

export default NotFoundPage;
